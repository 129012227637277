import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Colophon from '../components/colophon';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

function ThankYou() {
  return (
    <Layout>
      <Seo title="Thank You" />
      <div className="relative" id="contact">

      <div className="absolute bg-gray-900 inset-0 overflow-hidden" aria-hidden="true">
            <div className="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
              <div
                className="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[#f21d92] to-[#9c0dd9776fff] opacity-25"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
          </div>
      <div className="relative mx-auto max-w-7xl h-screen">
        <div className="pb-1 px-4 sm:px-6 lg:col-span-2 lg:px-8  xl:pr-12">

          <div className="mx-auto max-w-lg">
<Link to="/"> <StaticImage
            src="../images/b-aerial.png"
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="B-Aerial"
        /></Link>
        <div className="text-center">
                  <h1 className="mt-2 text-4xl font-bold tracking-tight text-primary sm:text-5xl">
                    Thank You.
                  </h1>
                  <p className="mt-2 text-base text-secondary">
                    Your Message Has Been Sent Successfully
                  </p>
                  <div className="mt-6">
                    <Link
                      to="/"
                      className="text-base font-medium text-primary hover:text-secondary"
                    >
                      Go back home
                      <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </div>
                </div>
          </div>
        </div>

      </div>
    </div>

    </Layout>
  );
}

export default ThankYou;
